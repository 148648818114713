import { Pipe, type PipeTransform } from '@angular/core';

import {
  WhiteLabelName,
  type WhiteLabelSettings,
} from '@esp/white-label/types';

@Pipe({
  name: 'whiteLabelLogo',
  standalone: true,
})
export class WhiteLabelLogoPipe implements PipeTransform {
  transform(
    whiteLabelSettings: WhiteLabelSettings | null,
    opts?: { preferColoredLogo?: boolean }
  ): string | null {
    if (
      !whiteLabelSettings ||
      whiteLabelSettings.WhiteLabelName === WhiteLabelName.None
    ) {
      return null;
    }

    if (opts?.preferColoredLogo) {
      return whiteLabelSettings.LogoUrlColored || whiteLabelSettings.LogoUrl;
    } else {
      return whiteLabelSettings.LogoUrl;
    }
  }
}
